<template>
    <div>
        <el-row type="flex" :gutter="20">
            <el-col :span="4" class="count_left">
                <div class="summary_item" v-for="(l, index) in list" :key="index">
                    <div class="title">{{ l.title }}</div>
                    <div class="number">
                        <span class="value">{{ l.value }}</span>
                        <span>kg</span>
                    </div>
                    <div class="number">
                        <span class="value">{{ l.value2 }}</span>
                        <span>方</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="20" class="handle">
                <div class="module_name">处置统计</div>
                <el-radio-group v-model="timeFlag" class="time-flag" size="mini" @change="redraw">
                    <el-radio-button :label="0">今日</el-radio-button>
                    <el-radio-button :label="1">本周</el-radio-button>
                    <el-radio-button :label="2">本月</el-radio-button>
                </el-radio-group>
                <div class="summary_chart" ref="summary_chart"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        created() {
            this.$http.post("/home/dealStat").then(res => {
                const { toYear, today } = res.detail;
                this.list[0].value = today[0].sum;
                this.list[0].value2 = today[1].sum;

                this.list[1].value = toYear[0].sum;
                this.list[1].value2 = toYear[1].sum;
            });
            this.init();
        },
        methods: {
            redraw() {
                this.init().then(() => {
                    this.resize();
                });
            },
            init() {
                return this.$http.post("/home/dealForm", this.timeFlag).then(res => {
                    const list = res.detail;
                    this.legends = list.map(v => v.wasteType.cName);
                    this.dateTimes = new Set();
                    list.forEach(t => {
                        t.list.forEach(d => {
                            this.dateTimes.add(d.dataTime);
                        });
                    });
                    this.series = list.map(t => {
                        return {
                            name: t.wasteType.cName,
                            type: "line",
                            // stack: "Total",
                            data: t.list.map(v => v.value)
                        };
                    });
                });
            },
            resize() {
                this.$nextTick(() => {
                    const c = Echarts.init(this.$refs.summary_chart);
                    const option = {
                        tooltip: {
                            trigger: "axis"
                        },
                        legend: {
                            data: this.legends,
                            top: 0,
                            right: 180
                        },
                        grid: {
                            left: 40,
                            top: 40,
                            // bottom: "15%"
                            right: 40,
                            bottom: 30
                        },
                        xAxis: {
                            type: "category",
                            boundaryGap: false,
                            data: Array.from(this.dateTimes)
                        },
                        yAxis: {
                            type: "value",
                            show: true,
                            axisLine: {
                                show: true
                            },
                            axisTick: {
                                show: true
                            },
                            splitLine: {
                                show: false
                            },
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: ["rgba(71, 134, 227, 0.1)", "rgba(71, 134, 227, 0.05)"]
                                }
                            }
                        },
                        series: this.series
                    };

                    c.setOption(option);
                });
            }
        },
        data() {
            return {
                list: [
                    {
                        value: "0",
                        value2: "0",
                        title: "今日委托订单数"
                    },
                    {
                        value: "0",
                        value2: "0",
                        title: "本月委托订单数"
                    }
                ],

                timeFlag: 0,
                legends: [],
                dateTimes: [],
                series: []
            };
        }
    };
</script>
<style lang="scss" scoped>
    .handle {
        position: relative;
    }
    .time-flag {
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 9;
    }
    .module_name {
        font-size: 14px;
        font-weight: bold;
        color: #4786e3;
    }

    .module_name::before {
        content: "";
        display: inline-block;
        width: 3px;
        background-color: #4786e3;
        height: 16px;
        vertical-align: middle;
        margin-right: 3px;
    }
    .count_left {
        display: flex;
        flex-direction: column;
        .summary_item {
            text-align: center;
            border: 1px solid #dbdbdb;
            margin-bottom: 10px;
            .title {
                line-height: 35px;
                background-color: #e6e6e6;
                font-size: 16px;
                font-weight: bold;
                color: #575757;
            }
            .number {
                color: #4786e3;
                & > span:nth-child(1) {
                    font-size: 36px;
                }
            }
        }
    }
    .summary_chart {
        height: 300px;
    }
</style>
