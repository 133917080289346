<template>
    <div class="home">
        <jy-homesum :list="summary" class="text_box home_item"></jy-homesum>
        <el-tabs type="card" v-model="active" class="tab-menu" @tab-click="tabChange">
            <el-tab-pane name="order" label="委托订单统计">
                <div class="module_name">委托订单统计</div>
                <order-count ref="c1" class="home_item"></order-count>
                <div class="module_name">今日调度委托订单</div>
                <dispatch-table></dispatch-table>
            </el-tab-pane>
            <el-tab-pane name="collect" label="固废收集统计">
                <collect-card></collect-card>
                <div class="module_name">固废收集产废企业排名</div>
                <collect-rank ref="c2"></collect-rank>
                <div class="module_name">固废收集统计</div>
                <collect-count ref="c3"></collect-count>
            </el-tab-pane>
            <el-tab-pane name="sortUse" label="分拣利用">
                <sort-use ref="c4"></sort-use>
            </el-tab-pane>
            <el-tab-pane name="handle" label="处置">
                <handle-count ref="c5"></handle-count>
                <div class="module_name">固废减量分析</div>
                <decrement-count ref="c6"></decrement-count>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import img1 from "@/assets/home/company/img1.png";
    import img2 from "@/assets/home/company/img2.png";
    import img3 from "@/assets/home/company/img3.png";
    import img4 from "@/assets/home/company/img4.png";
    import img5 from "@/assets/home/company/img5.png";
    import img6 from "@/assets/home/company/img6.png";
    import img7 from "@/assets/home/company/img7.png";

    import OrderCount from "./OrderCount.vue";
    import DispatchTable from "./DispatchTable.vue";
    import CollectCount from "./CollectCount.vue";
    import CollectRank from "./CollectRank.vue";
    import CollectCard from "./CollectCard.vue";
    import SortUse from "./SortUse.vue";
    import HandleCount from "./HandleCount.vue";
    import DecrementCount from "./DecrementCount.vue";
    export default {
        data() {
            return {
                summary: [
                    {
                        icon: img1,
                        info: "产废企业",
                        num: "0",
                        unit: "家"
                    },
                    {
                        icon: img2,
                        info: "分拣中心",
                        num: "0",
                        unit: "家"
                    },
                    {
                        icon: img3,
                        info: "固废利用单位",
                        num: "0",
                        unit: "家"
                    },
                    {
                        icon: img4,
                        info: "处置单位",
                        num: "0",
                        unit: "家"
                    },
                    {
                        icon: img5,
                        info: "运输车辆",
                        num: "0",
                        unit: "辆"
                    },
                    {
                        icon: img6,
                        info: "运输司机",
                        num: "0",
                        unit: "人"
                    },
                    {
                        icon: img7,
                        info: "节能减排",
                        num: "0",
                        unit: "kg"
                    }
                ],

                props: {
                    info: "unit"
                },

                active: "order"
            };
        },
        created() {
            this.$http.post("/home/countMsgCom").then(res => {
                this.summary[0].num = res.detail.unit0;
                this.summary[1].num = res.detail.unit3;
                this.summary[2].num = res.detail.unit1;
                this.summary[3].num = res.detail.unit2;
                this.summary[4].num = res.detail.vehicle;
                this.summary[5].num = res.detail.driver;
                this.summary[6].num = 30000;
            });
        },
        methods: {
            tabChange() {
                if (this.active === "collect") {
                    this.$refs.c2.resize();
                    this.$refs.c3.resize();
                } else if (this.active === "sortUse") {
                    this.$refs.c4.resize();
                } else if (this.active === "handle") {
                    this.$refs.c5.resize();
                    this.$refs.c6.resize();
                }
            }
        },
        components: {
            OrderCount,
            DispatchTable,
            CollectCount,
            CollectRank,
            CollectCard,
            SortUse,
            HandleCount,
            DecrementCount
        }
    };
</script>
<style scoped>
    .home_item {
        margin: 15px;
    }

    .module_name {
        font-size: 14px;
        font-weight: bold;
        color: #4786e3;
        margin: 10px 0;
    }

    .module_name::before {
        content: "";
        display: inline-block;
        width: 3px;
        background-color: #4786e3;
        height: 16px;
        vertical-align: middle;
        margin-right: 3px;
    }

    .tab-menu ::v-deep .el-tabs__item {
        background-color: #e6e6e6;
        color: #b5b5b5;
        width: 180px;
        text-align: center;
        position: relative;
    }
    .tab-menu ::v-deep .el-tabs__item.is-active {
        background-color: #fff;
        color: #4786e3;
    }

    .tab-menu ::v-deep .el-tabs__item.is-active::before {
        display: inline-block;
        content: "";
        width: 100%;
        height: 2px;
        background-color: #4786e3;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
