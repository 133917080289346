<template>
    <div class="count">
        <el-radio-group v-model="timeFlag" class="time-flag" size="mini" @change="redraw">
            <el-radio-button :label="0">今日</el-radio-button>
            <el-radio-button :label="1">本周</el-radio-button>
            <el-radio-button :label="2">本月</el-radio-button>
        </el-radio-group>
        <div class="collect-count" ref="chart"></div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        data() {
            return {
                timeFlag: 0,
                legends: [],
                dateTimes: [],
                series: []
            };
        },
        created() {
            this.init();
        },
        methods: {
            redraw() {
                this.init().then(() => {
                    this.resize();
                });
            },
            init() {
                return this.$http.post("/home/receiveForm", this.timeFlag).then(res => {
                    const list = res.detail;
                    this.legends = list.map(v => v.wasteType.cname);
                    this.dateTimes = new Set();
                    list.forEach(t => {
                        t.list.forEach(d => {
                            this.dateTimes.add(d.dateTime);
                        });
                    });
                    this.series = list.map(t => {
                        return {
                            name: t.wasteType.cname,
                            type: "line",
                            stack: "Total",
                            data: t.list.map(v => v.value)
                        };
                    });
                });
            },
            resize() {
                this.$nextTick(() => {
                    const eIns = Echarts.init(this.$refs.chart);
                    const option = {
                        tooltip: {
                            trigger: "axis"
                        },
                        title: {
                            left: "5%",
                            subtext: "kg"
                        },
                        legend: {
                            data: this.legends,
                            right: 200
                        },
                        grid: {
                            top: 40,
                            left: "5%",
                            right: "5%",
                            bottom: "10%"
                        },
                        xAxis: {
                            type: "category",
                            boundaryGap: false,
                            data: Array.from(this.dateTimes)
                        },
                        yAxis: {
                            type: "value",
                            show: true,
                            axisLine: {
                                show: true
                            },
                            axisTick: {
                                show: true
                            },
                            splitLine: {
                                show: false
                            },
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: ["rgba(71, 134, 227, 0.1)", "rgba(71, 134, 227, 0.05)"]
                                }
                            }
                        },
                        series: [
                            {
                                name: "一般工业固废",
                                type: "line",
                                stack: "Total",
                                data: [120, 132, 101, 134, 90]
                            },
                            {
                                name: "建筑垃圾",
                                type: "line",
                                stack: "Total",
                                data: [220, 182, 191, 234, 290]
                            }
                        ]
                    };
                    eIns.setOption(option);
                });
            }
        }
    };
</script>
<style lang="scss" scoped>
    .collect-count {
        height: 350px;
    }
    .count {
        position: relative;
    }
    .time-flag {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 9;
    }
</style>
