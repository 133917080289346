<template>
    <div>
        <el-row type="flex">
            <el-col :span="4" class="count_left">
                <div class="summary_item" v-for="(item, index) in list" :key="index">
                    <div class="title">{{ item.title }}</div>
                    <div class="number">
                        <span>{{ item.value }}</span>
                        <span>单</span>
                    </div>
                    <div v-if="item.percent">
                        {{ item.growth }}
                        <i :class="{ 'el-icon-top': !item.flag, 'el-icon-bottom': item.flag }"></i>
                        <span :style="{ color: item.flag ? 'red' : 'green' }">{{ item.percent }}</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="20">
                <el-radio-group v-model="timeFlag" class="time-flag" size="mini" @change="initForm">
                    <el-radio-button :label="'0'">今日</el-radio-button>
                    <el-radio-button :label="'1'">本周</el-radio-button>
                    <el-radio-button :label="'2'">本月</el-radio-button>
                </el-radio-group>
                <div class="summary_chart" ref="summary_chart"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        methods: {
            resize(legend, set, list) {
                this.$nextTick(() => {
                    const c = Echarts.init(this.$refs.summary_chart);
                    const s = list.map(t => {
                        return {
                            name: t.wasteType.cName,
                            type: "line",
                            // stack: "Total",
                            data: t.list.map(v => v.value)
                        };
                    });
                    const option = {
                        tooltip: {
                            trigger: "axis"
                        },
                        legend: {
                            data: legend,
                            top: 0,
                            right: 180
                        },
                        grid: {
                            left: 40,
                            top: 40,
                            // bottom: "15%"
                            right: 40,
                            bottom: 30
                        },
                        xAxis: {
                            type: "category",
                            boundaryGap: false,
                            // boundaryGap: ['20%', '20%'],
                            data: Array.from(set)
                        },
                        yAxis: {
                            type: "value",
                            show: true,
                            axisLine: {
                                show: true
                            },
                            axisTick: {
                                show: true
                            },
                            splitLine: {
                                show: false
                            },
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: ["rgba(71, 134, 227, 0.1)", "rgba(71, 134, 227, 0.05)"]
                                }
                            }
                        },
                        series: s
                    };

                    c.setOption(option);
                });
            },

            initSat() {
                this.$http.post("/home/wastesStat").then(res => {
                    const { day, week, month } = res.detail;
                    this.list[0].value = day.count;
                    this.list[1].value = week.count;
                    this.list[1].percent = week.percent * 100 + "%";
                    this.list[1].flag = week.percent * 100 >= 0 ? 0 : 1;
                    this.list[2].value = month.count;
                    this.list[2].percent = month.percent * 100 + "%";
                    this.list[2].flag = month.percent * 100 >= 0 ? 0 : 1;
                });
            },
            initForm() {
                this.$http.post("/home/wastesForm", this.timeFlag).then(res => {
                    const list = res.detail;
                    const legend = list.map(v => v.wasteType.cName);
                    const set = new Set();
                    list.forEach(t => {
                        t.list.forEach(d => {
                            set.add(d.dateTime);
                        });
                    });
                    this.resize(legend, set, list);
                });
            }
        },
        activated(){
            this.initSat();
            this.initForm();
            clearInterval(this.interval)
            this.interval = window.setInterval(()=>{
                this.initSat()
                this.initForm()
            },3000)
        },
        deactivated(){
            clearInterval(this.interval)
        },
        beforeDestroy(){
            clearInterval(this.interval)
        },
        data() {
            return {
                list: [
                    {
                        value: "0",
                        growth: "",
                        percent: "",
                        title: "今日委托订单数"
                    },
                    {
                        value: "0",
                        growth: "同比上周",
                        percent: "",
                        title: "本周委托订单数",
                        flag: 0
                    },
                    {
                        value: "0",
                        growth: "同比上月",
                        percent: "",
                        title: "本月委托订单数",
                        flag: 0
                    }
                ],

                timeFlag: '0',
                interval:null
            };
        }
    };
</script>
<style lang="scss" scoped>
    .el-col {
        position: relative;
    }
    .count_left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .summary_item {
            text-align: center;
            border: 1px solid #dbdbdb;
            .title {
                line-height: 35px;
                background-color: #e6e6e6;
                font-size: 16px;
                font-weight: bold;
                color: #575757;
            }
            .number {
                color: #4786e3;
                & > span:nth-child(1) {
                    font-size: 36px;
                }
            }
        }
    }
    .summary_chart {
        height: 300px;
    }
    .time-flag {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
    }
</style>
