<template>
    <div>
        <el-row type="flex" :gutter="20">
            <el-col :span="4" class="count_left">
                <div class="summary_item" v-for="(item, index) in list" :key="index">
                    <div class="title">{{ item.title }}</div>
                    <div class="number">
                        <span>{{ item.value }}</span>
                        <span>kg</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="20">
                <div class="module_name">回收类别净重</div>
                <el-radio-group v-model="timeFlag" class="time-flag" size="mini" @change="redraw">
                    <el-radio-button :label="0">今日</el-radio-button>
                    <el-radio-button :label="1">本周</el-radio-button>
                    <el-radio-button :label="2">本月</el-radio-button>
                </el-radio-group>
                <div class="summary_chart" ref="summary_chart"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        created() {
            this.$http.post("/home/recycleStat").then(res => {
                const { toYear, today } = res.detail;
                this.list[0].value = toYear;
                this.list[1].value = today;
            });

            this.init();
        },
        methods: {
            redraw() {
                this.init().then(() => {
                    this.resize();
                });
            },
            init() {
                return this.$http.post("/home/recycleForm", this.timeFlag).then(res => {
                    const list = res.detail;
                    this.yAxisData = list.map(v => v.recycle.cName);
                    this.seriesData = list.map(v => v.value);
                });
            },
            resize() {
                this.$nextTick(() => {
                    const c = Echarts.init(this.$refs.summary_chart);
                    const option = {
                        grid: {
                            top: "8%",
                            left: "5%",
                            bottom: "10%"
                        },
                        xAxis: {
                            type: "value",
                            axisLine: {
                                show: true
                            },
                            axisTick: {
                                show: true
                            }
                            // boundaryGap: [0, 0.01]
                        },
                        yAxis: {
                            type: "category",
                            data: this.yAxisData
                        },

                        series: [
                            {
                                name: "回收类别净重",
                                type: "bar",
                                data: this.seriesData
                            }
                        ]
                    };

                    c.setOption(option);
                });
            }
        },
        data() {
            return {
                list: [
                    {
                        value: "0",
                        title: "本年度回收量"
                    },
                    {
                        value: "0",
                        title: "今日回收量"
                    }
                ],

                timeFlag: 0,

                yAxisData: [],
                seriesData: []
            };
        }
    };
</script>
<style lang="scss" scoped>
    .time-flag {
        position: absolute;
        right: 20px;
        top: 0;
    }
    .module_name {
        font-size: 14px;
        font-weight: bold;
        color: #4786e3;
    }

    .module_name::before {
        content: "";
        display: inline-block;
        width: 3px;
        background-color: #4786e3;
        height: 16px;
        vertical-align: middle;
        margin-right: 3px;
    }
    .count_left {
        display: flex;
        flex-direction: column;
        .summary_item {
            text-align: center;
            border: 1px solid #dbdbdb;
            margin-bottom: 10px;
            .title {
                line-height: 35px;
                background-color: #e6e6e6;
                font-size: 16px;
                font-weight: bold;
                color: #575757;
            }
            .number {
                color: #4786e3;
                & > span:nth-child(1) {
                    font-size: 36px;
                }
            }
        }
    }
    .summary_chart {
        height: 300px;
    }
</style>
