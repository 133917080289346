<template>
    <div>
        <jy-table :data="dataList">
            <jy-table-column label="序号" type="index"></jy-table-column>
            <jy-table-column label="委托单号" prop="wOrderId" min-width="160"></jy-table-column>
            <jy-table-column label="产废企业" prop="unitNa" min-width="120"></jy-table-column>
            <jy-table-column label="分拣中心" prop="tUnitNa" min-width="120"></jy-table-column>
            <jy-table-column label="关联调度单号" prop="scheduleId" min-width="160">
                <template v-slot="scope">{{ scope.row.scheduleOrder | scheduleIdFilter }}</template>
            </jy-table-column>
            <jy-table-column label="状态" prop="status" min-width="120">
                <template v-slot="scope">{{ scope.row.status | statusFilter }}</template>
            </jy-table-column>
            <jy-table-column label="车牌号" prop="plateNum" min-width="120">
                <template v-slot="scope">{{ scope.row.scheduleOrder | vehicleFilter }}</template>
            </jy-table-column>
            <jy-table-column label="司机" prop="driverNa" min-width="120">
                <template v-slot="scope">{{ scope.row.scheduleOrder | driverFilter }}</template>
            </jy-table-column>
            <jy-table-column label="调度发车时间" prop="pTime" width="160">
                <template v-slot="scope">{{ scope.row.scheduleOrder | schTimeFilter }}</template>
            </jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
    </div>
</template>

<script>
    export default {
        filters:{
            statusFilter:(v)=>{
                //0-待派单；1-已拒绝；2-已派单；3-运输中；4-已取消；5-已完成
                let statusList = ['待派单','已拒绝','已派单','运输中','已取消','已完成']
                return statusList[v]
            },
            scheduleIdFilter:(v)=>{
                if (!v) {return '-'}
                return v.scheduleId
            },
            vehicleFilter:(v)=>{
                if (!v) {return '-'}
                if (!v.vehicleInfo) {return '-'}
                return v.vehicleInfo.plateNum
            },
            driverFilter:(v)=>{
                if (!v) {return '-'}
                if (!v.driverList) {return '-'}
                if (!v.driverList.length) {return '-'}
                return v.driverList.map(one=>one.cName).join(',')
            },
            schTimeFilter:(v)=>{
                if (!v) {return '-'}
                if (!v.pTime) {return '-'}
                return v.pTime
            }
        },
        methods: {
            handleView(row) {
                this.$router.push({
                    name: "order",
                    params: {
                        wOrderId: row.wOrderId
                    }
                });
            },
            showTrack() {},
            init() {
                this.$http.post("/home/wastesListToDayList").then(res => {
                    this.dataList = res.detail.list;
                });
            }
        },
        activated(){
            this.init();
            clearInterval(this.interval)
            this.interval = window.setInterval(()=>{
                this.init()
            },3000)
        },
        deactivated(){
            clearInterval(this.interval)
        },
        beforeDestroy(){
            clearInterval(this.interval)
        },
        data() {
            return {
                interval:null,
                dataList: [],
                operateList: [
                    {
                        name: "查看委托订单",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return true;
                        }
                    }/*,
                    {
                        name: "运输轨迹",
                        icon: "el-icon-delete",
                        fun: this.showTrack,
                        isShow: () => {
                            return true;
                        }
                    }*/
                ]
            };
        }
    };
</script>
<style></style>
