import { render, staticRenderFns } from "./OrderCount.vue?vue&type=template&id=67e39323&scoped=true&"
import script from "./OrderCount.vue?vue&type=script&lang=js&"
export * from "./OrderCount.vue?vue&type=script&lang=js&"
import style0 from "./OrderCount.vue?vue&type=style&index=0&id=67e39323&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e39323",
  null
  
)

export default component.exports