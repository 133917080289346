<template>
    <div class="decrement">
        <el-radio-group v-model="wasteTId" size="mini" @change="redraw">
            <el-radio-button v-for="t in wasteTypes" :key="t.wasteTId" :label="t.wasteTId">{{ t.cName }}</el-radio-button>
        </el-radio-group>
        <div class="summary_chart" ref="summary_chart"></div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        async created() {
            const wasteRes = await this.$http.post("/wasteType/listAll");
            this.wasteTypes = wasteRes.detail;
            this.wasteTId = this.wasteTypes[0].wasteTId;

            this.init();
        },
        methods: {
            redraw() {
                this.init().then(() => {
                    this.resize();
                });
            },
            init() {
                return this.$http.post("/home/gather", this.wasteTId).then(res => {
                    const { keys, deals, percents, receives } = res.detail;
                    this.xAxisData = keys;
                    this.series = [
                        {
                            name: "回收利用",
                            type: "bar",
                            data: receives
                        },
                        {
                            name: "处置量",
                            type: "bar",
                            data: deals
                        },
                        {
                            name: "处置比例",
                            type: "bar",
                            data: percents
                        }
                    ];
                    console.log(res);
                });
            },
            resize() {
                this.$nextTick(() => {
                    const c = Echarts.init(this.$refs.summary_chart);
                    const option = {
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                                type: "shadow"
                            }
                        },
                        legend: {
                            right: 20,
                            top: 40
                        },
                        grid: {
                            left: "5%",
                            right: "5%",
                            bottom: "10%",
                            top: 70
                        },
                        xAxis: {
                            type: "category",
                            data: this.xAxisData
                        },
                        yAxis: {
                            type: "value",
                            show: true,
                            axisLine: {
                                show: true
                            },
                            axisTick: {
                                show: true
                            },
                            splitLine: {
                                show: false
                            },
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: ["rgba(71, 134, 227, 0.1)", "rgba(71, 134, 227, 0.05)"]
                                }
                            }
                        },
                        series: this.series
                    };

                    c.setOption(option);
                });
            }
        },
        data() {
            return {
                wasteTypes: [],
                wasteTId: "",

                series: [],
                xAxisData: []
            };
        }
    };
</script>
<style lang="scss" scoped>
    .decrement {
        position: relative;
    }
    .el-radio-group {
        position: absolute;
        right: 20px;
        top: 0;
        z-index: 9;
    }
    .summary_chart {
        height: 350px;
    }
</style>
