<template>
    <div class="rank">
        <el-radio-group v-model="wasteTId" size="mini" @change="redraw">
            <el-radio-button v-for="t in wasteTypes" :key="t.wasteTId" :label="t.wasteTId">{{ t.cName }}</el-radio-button>
        </el-radio-group>
        <div class="collect-rank" ref="rank"></div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        async created() {
            const wasteRes = await this.$http.post("/wasteType/listAll");
            this.wasteTypes = wasteRes.detail;
            this.wasteTId = this.wasteTypes[0].wasteTId;

            this.init();
        },
        methods: {
            redraw() {
                this.init().then(() => {
                    this.resize();
                });
            },
            init() {
                return this.$http.post("/home/sumWastesUnits", this.wasteTId).then(res => {
                    this.xAxisData = res.detail.map(v => v.unit.cName);
                    this.seriesData = res.detail.map(v => v.sum);
                });
            },
            resize() {
                this.$nextTick(() => {
                    const dom = this.$refs.rank;
                    const eIns = Echarts.init(dom);
                    const option = {
                        title: {
                            left: "5%",
                            subtext: "kg"
                        },
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                                type: "shadow"
                            }
                        },
                        grid: {
                            left: "5%",
                            right: "5%",
                            bottom: "10%",
                            top: 40
                        },
                        xAxis: {
                            type: "category",
                            data: this.xAxisData
                        },
                        yAxis: {
                            type: "value",
                            show: true,
                            axisLine: {
                                show: true
                            },
                            axisTick: {
                                show: true
                            },
                            splitLine: {
                                show: false
                            },
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: ["rgba(71, 134, 227, 0.1)", "rgba(71, 134, 227, 0.05)"]
                                }
                            }
                        },
                        series: [
                            {
                                name: "固废收集产废企业排名",
                                type: "bar",
                                barMaxWidth: 100,
                                data: this.seriesData
                            }
                        ]
                    };
                    eIns.setOption(option);
                });
            }
        },
        data() {
            return {
                wasteTId: "",
                wasteTypes: [],

                xAxisData: [],
                seriesData: []
            };
        }
    };
</script>
<style scoped>
    .rank {
        position: relative;
    }
    .collect-rank {
        height: 350px;
    }

    .el-radio-group {
        position: absolute;
        right: 20px;
        top: 0;
        z-index: 9;
    }
</style>
