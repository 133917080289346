<template>
    <div class="card">
        <el-row class="collect-item" v-for="l in list" :key="l.name">
            <div class="title">{{ l.name }}</div>
            <div v-if="l.pName">
                <el-row class="number" type="flex">
                    <el-col :span="12">
                        <span class="value">{{ l.value1 }}</span>
                        <span>{{ l.unit1 }}</span>
                    </el-col>
                    <el-col :span="12" class="percent">
                        <span>{{ l.pName }}</span>
                        <span>
                            <i :style="{ color: l.p1 >= 0 ? 'green' : 'red' }" :class="{ 'el-icon-top': l.p1 >= 0, 'el-icon-bottom': l.p1 < 0 }"></i>
                            <span :style="{ color: l.p1 >= 0 ? 'green' : 'red' }">{{ l.p1 }}%</span>
                        </span>
                    </el-col>
                </el-row>
                <el-row class="number" type="flex">
                    <el-col :span="12">
                        <span class="value">{{ l.value2 }}</span>
                        <span>{{ l.unit2 }}</span>
                    </el-col>
                    <el-col :span="12" class="percent">
                        <span>
                            <i :style="{ color: l.p1 >= 0 ? 'green' : 'red' }" :class="{ 'el-icon-top': l.p2 >= 0, 'el-icon-bottom': l.p2 < 0 }"></i>
                            <span :style="{ color: l.p1 >= 0 ? 'green' : 'red' }">{{ l.p2 }}%</span>
                        </span>
                    </el-col>
                </el-row>
            </div>
            <div v-else>
                <div class="number">
                    <span class="value">{{ l.value1 }}</span>
                    <span>{{ l.unit1 }}</span>
                </div>
                <div class="number">
                    <span class="value">{{ l.value2 }}</span>
                    <span>{{ l.unit2 }}</span>
                </div>
            </div>
        </el-row>
    </div>
</template>

<script>
    export default {
        created() {
            this.$http.post("/home/receiveStat").then(res => {
                const { dayDataFromYesterday, dayDataFromOrder, dayDataFromUnit3, dayDataFromAll, weekData, monthData, yearData } = res.detail;
                this.list[0].value1 = dayDataFromOrder[0].value;
                this.list[0].value2 = dayDataFromOrder[1].value;

                this.list[1].value1 = dayDataFromUnit3[0].value;
                this.list[1].value2 = dayDataFromUnit3[1].value;

                this.list[2].value1 = dayDataFromAll[0].value;
                this.list[2].value2 = dayDataFromAll[1].value;

                this.list[3].value1 = dayDataFromYesterday[0].value;
                this.list[3].value2 = dayDataFromYesterday[1].value;

                this.list[4].value1 = weekData[0].value;
                this.list[4].value2 = weekData[1].value;
                this.list[4].p1 = weekData[0].percent * 100;
                this.list[4].p2 = weekData[1].percent * 100;

                this.list[5].value1 = monthData[0].value;
                this.list[5].value2 = monthData[1].value;
                this.list[5].p1 = monthData[0].percent * 100;
                this.list[5].p2 = monthData[1].percent * 100;

                this.list[6].value1 = yearData[0].value;
                this.list[6].value2 = yearData[1].value;
                this.list[6].p1 = yearData[0].percent * 100;
                this.list[6].p2 = yearData[1].percent * 100;
            });
        },
        data() {
            return {
                list: [
                    {
                        name: "今日环服上门接收量净重",
                        value1: "0",
                        unit1: "kg",
                        value2: "0",
                        unit2: "方"
                    },
                    {
                        name: "今日企业自送接收量净重",
                        value1: "0",
                        unit1: "kg",
                        value2: "0",
                        unit2: "方"
                    },
                    {
                        name: "今日总接收量净重",
                        value1: "0",
                        unit1: "kg",
                        value2: "0",
                        unit2: "方"
                    },
                    {
                        name: "昨日总接收量净重",
                        value1: "0",
                        unit1: "kg",
                        value2: "0",
                        unit2: "方"
                    },
                    {
                        name: "本周总接收量净重",
                        value1: "0",
                        unit1: "kg",
                        value2: "0",
                        unit2: "方",
                        p1: 0,
                        p2: 0,
                        pName: "同比上周"
                    },
                    {
                        name: "本月总接收量净重",
                        value1: "0",
                        unit1: "kg",
                        value2: "0",
                        unit2: "方",
                        p1: 0,
                        p2: 0,
                        pName: "同比上月"
                    },
                    {
                        name: "本年度总接收量净重",
                        value1: "0",
                        unit1: "kg",
                        value2: "0",
                        unit2: "方",
                        p1: 0,
                        p2: 0,
                        pName: "同比上年"
                    }
                ]
            };
        }
    };
</script>
<style lang="scss" scoped>
    .card {
        display: flex;
        justify-content: space-around;
        .collect-item {
            text-align: center;
            border: 1px solid #dbdbdb;
            width: 200px;
            border-radius: 3px 3px 0px 0px;
            .title {
                line-height: 35px;
                background-color: #e6e6e6;
                font-size: 16px;
                font-weight: bold;
                color: #575757;
            }
            .number {
                color: #4786e3;
                .value {
                    font-size: 30px;
                }
                .percent {
                    padding-bottom: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
    }
</style>
